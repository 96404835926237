import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CommonLayout from 'layouts/CommonLayout';
import { Layout } from '@a-cloud-guru/rainbow-ui';
import { Container } from 'components/container';
import { imagePaths } from '../components/util/commonImageUrls';

const BlankLayout = props => (
  <CommonLayout>
    <StyledLayout className={props.className}>
      <PageBody>
        <Container>
          {props.showLogo && (
            <LogoContainer>
              <img src={imagePaths.logoDesktop}></img>
            </LogoContainer>
          )}
          {props.children}
        </Container>
      </PageBody>
    </StyledLayout>
  </CommonLayout>
);

BlankLayout.propTypes = {
  className: PropTypes.string,
  showLogo: PropTypes.bool,
  children: PropTypes.node
};

const StyledLayout = styled(Layout)`
  background-color: #090258;
  min-height: 100vh;

  &.ant-layout {
    height: auto;
  }
`;

const PageBody = styled.div`
  flex-grow: 1;
`;

const LogoContainer = styled.div`
  text-align: center;
  margin-top: 2em;

  img {
    max-width: 100%;
  }
`;

export default BlankLayout;
